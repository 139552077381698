<template>
<div>
    <v-container>

        <!-- EDICION Y CREACION DE REQUERIMIENTOS EN VENTANA MODAL  -->
        <div v-if="dialog">
            <v-dialog class="elevation-0" v-model="dialog" width="100%" persistent transition="dialog-bottom-transition">
                <atc-requirement-edit :value="RequirementEdit.ReqID" @close="dialog = false" :itemOC="selectedGestion[0]" :visible="visibleCard"></atc-requirement-edit>
            </v-dialog>
        </div>

        <v-row>

            <v-col cols="12">
                <!--  <s-toolbar dark label="Gestión de Solicitudes"></s-toolbar> -->

                <v-card class="mt-2" outlined>

                    <v-row style="margin:auto">
                        <v-col cols="12">

                            <!-- <v-card outlined class="mx-auto my-1 pb-2" width="100%"> -->
                                <s-crud excel show-group-by noFull height="400" ref="grid" search-input="" add-no-function :filter="filter" :view="selected.length > 0 ? true : false" @edit="edit()" :config="config" @rowSelected="rowSelected($event)">


                                    <template v-slot:filter>
                                        <v-container>
                                            <v-container>
                                                <v-row justify="center">

                                                    <v-col lg="3" md="3" cols="6" class="s-col-form" v-if="$fun.isAdmin()">
                                                        <s-select label="Usuario" placeholder="Digite Usuario" autocomplete clearable item-text="NtpFullName"  v-model="filter.UsrCreateID" item-value="UsrID" :items="itemsUsers"></s-select>
                                                    </v-col>

                                                     <!-- <v-col lg="3" md="3" cols="6" class="s-col-form" v-if="$fun.isAdmin()">
                                                        <c-select-area full clearable noDefault v-model="filter.AreID" label="Área" />
                                                    </v-col> -->

                                                    <v-col lg="3" md="3" cols="6" class="s-col-form">
                                                        <s-select-definition clearable label="Estados" noDefault v-model="filter.ReqStatus" :def="1187" />
                                                    </v-col>
                                                   
                                                    <v-col lg="3" md="3" cols="6" class="s-col-form">
                                                        <s-date clearable v-model="filter.BeginDate" label="Fecha Inicio" />
                                                    </v-col>
                                                    <v-col lg="3" md="3" cols="6" class="s-col-form">
                                                        <s-date clearable  v-model="filter.EndDate" label="Fecha Fin" />
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-container>
                                    </template>
                                    <template v-slot:ReqStatusName="{ row }">
                                        <v-chip x-small :color="row.ReqStatusColor">
                                            {{ row.ReqStatusName }}<span>
                                                <v-btn style="margin-left:10px" icon x-small elevation="0" dark @click="dialogApprovalRequirement= true"><i class="fas fa-eye"></i></v-btn>
                                            </span>
                                        </v-chip>

                                    </template>

                                    <template v-slot:CodSales="{ row }">
                                            {{ row.CodSales == '0' ? '' : row.CodSales }}
                                    </template>

                                    <template v-slot:TypePriorityName="{ row }">
                                        <v-chip x-small :color="row.TypePriorityColor">
                                            {{ row.TypePriorityName }}
                                        </v-chip>
                                    </template>
                                </s-crud>
                            <!-- </v-card> -->
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <!-- NIVELES DE APROBACION DE REQ-->
        <v-dialog v-model="dialogApprovalRequirement" v-if="dialogApprovalRequirement" width="60%" style="display: inline-table;">
            <ApprovalLevelsDocument :DocEntry="ReqID" :TypeObject="TrsID" />
        </v-dialog>
    </v-container>

</div>
</template>

<script>
//Services
import _sRequirement from "@/services/CustomerSupport/AtcRequirementService";
import _sPermit from "@/services/Logistics/PermitService";

import AtcRequirementEdit from "@/views/CustomerSupport/Requirement/AtcRequirementEdit";
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";

export default {

    components: {
        AtcRequirementEdit,
        ApprovalLevelsDocument
    },
    data() {
        return {
            itemsUsers: [],
            processing: false,
            messageProcessing: "",
            alert: false,
            visibleCard :false,
            filter: {
                CodSales: "-2",
                AreID: 0,
                UsrCreateID: null,
                ReqStatus:4
            },

            dialogApprovalRequirement: false,
            selectedGestion: [],
            DocEntry: null,
            ReqID: null,
            TrsID: null,
            RequirementEdit: {},
            dialog: false,
            selected: [],
            config: {
                model: {
                    ReqDocumentNumber: "ID",
                    ReqStatusName: "",
                    ReqDate: "datetime",
                    SecUpdate: "datetime",
                    TypePriorityName: "string",
                    MailOK: "string",
                    ReqDay: "",
                    CodSales: ""
                },
                service: _sRequirement,
                headers: [{
                        text: "ID",
                        value: "ReqID",
                        sortable: false,
                        width: 40
                    },

                    {
                        text: "N° Solicitud",
                        value: "ReqDocumentNumber",
                        sortable: false,
                        width: 100,
                    },

                    {
                        text: "Tipo Solicitud",
                        value: "TrsName",
                        sortable: false,
                        width: 200,
                    },
                    {
                        text: "Medio",
                        value: "TypeMedioName",
                        sortable: false,
                        width: 100,
                    },
                    {
                        text: "Tipo",
                        value: "Type",
                        sortable: false,
                        width: 100,
                    },
                    {
                        text: "Cod. Venta",
                        value: "CodSales",
                        sortable: false,
                    },
                    {
                        text: "Lote",
                        value: "NroLot",
                        sortable: false,
                    },
                    {
                        text: "Cliente",
                        value: "CustomerName",
                        sortable: false,
                        width: 150,
                    },

                    {
                        text: "Registrado Por",
                        value: "UsrName",
                        sortable: false,
                        width: 200,

                    },
                    {
                        text: "Estado",
                        value: "ReqStatusName",
                        sortable: false,
                        width: 50,
                        align: "center",
                    },
                    {
                        text: "Area Proceso",
                        value: "PstName",
                        sortable: false,
                        width: 200,
                        align: "center",
                    },
                    {
                        text: "Estado Proceso",
                        value: "AlvProcessName",
                        sortable: false,
                        width: 150,
                        align: "center",
                    },

                    {
                        text: "Prioridad",
                        value: "TypePriorityName",
                        sortable: false,
                        width: 50,
                        align: "center",
                    },
                    {
                        text: "Fecha Inicio",
                        value: "ReqDate",
                        sortable: false,
                        width: 110
                    },
                    {
                        text: "Fecha Fin",
                        value: "SecUpdate",
                        sortable: false,
                        width: 140
                    },
                    {
                        text: "Tiemp. Proceso",
                        value: "ReqDay",
                        sortable: false,
                        width: 40,
                        align: "center",
                    },

                    {
                        text: "Área",
                        value: "AreName",
                        sortable: false,
                        width: 100,
                    },
                    {
                        text: "Ok",
                        value: "MailOK",
                        sortable: false,
                        width: 50,
                    },

                ],
            },
        };
    },

    watch: {
        dialog() {
            this.$refs.grid.refresh();
        },
    },

    created() {
        _sPermit.users(this.$fun.getUserID()).then((r) => {
            this.itemsUsers = r.data;
        });
        this.filter.UsrCreateID = this.$fun.isAdmin() ? null : this.$fun.getUserID();
        if (!this.$fun.isAdmin()) this.filter.AreID = this.$fun.getUserInfo().CtrContract.AreID;
    },
    methods: {

        rowSelected(item) {
            this.selected = item;
            //console.log(this.selected)
            if (item != null) {
                if (item.length > 0) {
                    if (item[0].ReqID != null)
                        this.ReqID = item[0].ReqID
                        if(item[0].CodSales=="0"){
                            this.visibleCard=false
                        }else{
                            this.visibleCard=true
                        }

                        //console.log("item", item)
                    this.TrsID = item[0].TrsID
                }
            }
        },

        //Editar Requerimiento
        edit() {
            this.RequirementEdit = this.selected.length > 0 ? this.selected[0] : null;
            this.dialog = true;
        },

    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
